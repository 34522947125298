import React from "react";
import styled from "styled-components";

import RectangleButton from "../../shared/components/RectangleButton";
import { toTitleCase } from "../../shared/utils/toTitleCase";
import PostListItemWithImage from "../../shared/components/PostListItemWithImage";
import Divider from "../../shared/components/Divider";
import { getAccurateTimeToRead } from "../../shared/utils/getAccurateTimeToRead";

const PostListWithImage = ({
  posts,
  iconLabel = "View All",
  link,
  searchButtonLabel = "Search",
  searchLink,
  title,
  showDivider = false,
}) => {
  const classPrefix = "post-list";
  const postsExist = posts && posts.length > 0;

  return (
    <PostListDiv>
      {showDivider && <Divider />}
      {title && (
        <div className={`${classPrefix}__header`}>
          <PostListTitle>{toTitleCase(title)}</PostListTitle>
          <div className="post-list__btns">
            {searchButtonLabel && (
              <div className={`${classPrefix}__rectangle-button`}>
                <RectangleButton iconLabel={searchButtonLabel} link={searchLink} />
              </div>
            )}
            {iconLabel && (
              <div className={`${classPrefix}__rectangle-button`}>
                <RectangleButton iconLabel={iconLabel} link={link} />
              </div>
            )}
            </div>
        </div>
      )}
      <div className={classPrefix}>
        {postsExist &&
          posts.map(({ id, fields, timeToRead, frontmatter }) => (
            <PostListItemWithImage
              key={id}
              id={id}
              imageURL={frontmatter.cover && frontmatter.cover.publicURL}
              description={frontmatter.description && frontmatter.description}
              title={frontmatter.title}
              url={fields.slug}
              date={frontmatter.date}
              timeToRead={getAccurateTimeToRead(timeToRead)}
              tags={frontmatter.tags}
            />
          ))}
      </div>
    </PostListDiv>
  );
};

const PostListDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  padding: 1rem 0;
  margin: 0 auto;
  width: 50%;

  .post-list__btns {
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 768px) {
    width: 80%;

    .post-list__btns {
      display: flex;
      flex-direction: row;
    }
  }

  a {
    color: ${(props) => props.theme.color.defaultText};
    text-decoration: none;
  }

  .post-list__rectangle-button {
    a {
      color: ${(props) => props.theme.color.white};
      text-decoration: none;
      font-weight: 700;
    }
  }

  .post-list__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    border-bottom: 1.3px solid ${(props) => props.theme.color.lightGrey};
    padding-right: 0.5rem;
  }

  .post-list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
  }

  @media (max-width: 786px) {
    width: 80%;

    .post-list {
      justify-content: center;
      width: 100%;
    }

    .post-list__header {
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: center;
      padding-bottom: 0.5em;
      text-align: center;
    }
  }

  @media (max-width: 1024px) {
    width: 65%;
  }
`;

const PostListTitle = styled.h2`
  font-size: 1.5rem;
  color: ${(props) => props.theme.color.darkGrey};
`;

export default PostListWithImage;
