import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { toTitleCase } from "../../../shared/utils/toTitleCase";
import StyledPostItemImage from "../PostItemImage";
import TagList from "../TagList";
import { mediumDateFormatter } from "../../utils/dateFormatter";

const PostListItemWithImage = ({
  id,
  imageURL,
  title,
  description,
  url,
  date,
  timeToRead,
  tags,
}) => {
  const getPostImageURL = () => {
    return typeof imageURL === "string" ? imageURL : "";
  };

  return (
    <PostListItemOuterDiv id={`${id}-post-list-item`}>
      <PostListLink to={url}>
        <StyledPostItemImage
          imageURL={getPostImageURL()}
          postTitle={title}
          boxShadow={false}
        />
        <div className="post-title">{toTitleCase(title)}</div>

        <div className={"post-date-read-time-wrapper"}>
          <div className="post-date">{mediumDateFormatter(date)}</div>
          <div className="post-time-to-read">{timeToRead} min read</div>
        </div>
        {description && <div className="post-description">{description}</div>}
      </PostListLink>
      <div className={"post-list-item__tags"}>
        <TagList tags={tags} showIcon={true} />
      </div>
    </PostListItemOuterDiv>
  );
};

const PostListItemOuterDiv = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 1em 0;
  padding: 1em;
  width: 40%;
  min-width: 35%;
  height: auto;
  background-color: ${(props) => props.theme.color.lighterGrey};
  border-radius: 10px;

  &:hover {
    background-color: ${(props) => props.theme.color.white};
  }

  .post-title {
    padding: 0.3em 0;
    font-size: 1.2rem;
    font-weight: 600;
  }

  .post-date-read-time-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 0.6em;
    font-size: 0.8rem;
    color: grey;
    border-bottom: 1.3px solid ${(props) => props.theme.color.lightGrey};
    margin-bottom: 0.5em;
  }

  .post-list-item__tags {
    font-size: 0.8em;
  }

  .post-description {
    font-size: 0.9em;
    font-style: italic;
    margin-bottom: 0.5em;
    color: ${(props) => props.theme.color.defaultText};
  }

  @media (max-width: 786px) {
    width: 100%;
  }
`;

const PostListLink = styled(Link)`
  text-decoration: none;
  width: 100%;
`;

export default PostListItemWithImage;
